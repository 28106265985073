.user-dashboard-card{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 200px;
    margin:5px;
    cursor: pointer;
}
.user-dashboard-card:hover {
    box-shadow: 0 5px 44px 0 #00000040;
    transition: .4s;
}