//  @import "../../../node_modules/bootstrap/scss/bootstrap.scss";
// Functions first
@import "../../../node_modules/bootstrap/scss/functions";



// Required Bootstrap imports
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/variables-dark";
@import "../../../node_modules/bootstrap/scss/maps";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/nav";
@import "../../../node_modules/bootstrap/scss/navbar";
@import "../../../node_modules/bootstrap/scss/transitions";
@import "../../../node_modules/bootstrap/scss/utilities";




@mixin display-flex {
    display: flex;
}
@mixin align-items($value) {
    align-items: $value;
}
@mixin transition($value) {
    transition: $value;
}
@mixin border-radius($value) {
    border-radius: $value;
}
$white: #ffff;
$font-weight-light: 300;
$font-weight-medium: 500;

////////// SIDEBAR ////////

$sidebar-width-lg: 220px;
$sidebar-width-mini: 145px;
$sidebar-width-icon: 70px;

$sidebar-light-bg: #f4f5f7;
$sidebar-light-menu-color: #484848;
$sidebar-light-submenu-color: $sidebar-light-menu-color;
$sidebar-light-menu-active-bg: #fff;
$sidebar-light-menu-active-color: #1f3bb3;
$sidebar-light-menu-hover-bg: #fff;
$sidebar-light-menu-hover-color: #1f3bb3;
$sidebar-light-submenu-active-color: #1f3bb3;
$sidebar-light-submenu-hover-color: #000;
$sidebar-light-menu-icon-color: $sidebar-light-menu-color;
$sidebar-light-menu-arrow-color: #bfccda;

$sidebar-menu-font-size: 12px;
$sidebar-menu-padding-left: 35px;
$sidebar-menu-padding-right: 35px;
$sidebar-menu-padding-top: 10px;
$sidebar-menu-padding-bottom: 10px;

$sidebar-submenu-padding: 0.25rem 0 0 3.07rem;
$sidebar-submenu-font-size: 12px;
$sidebar-submenu-item-padding: 0.7rem 1rem;

$sidebar-icon-font-size: 22px;
$sidebar-arrow-font-size: 0.625rem;

$sidebar-profile-bg: transparent;
$sidebar-profile-padding: 0.75rem 1.625rem 0.75rem 1.188rem;

$sidebar-mini-menu-padding: 0.8125rem 1rem 0.8125rem 1rem;

$sidebar-icon-only-menu-padding: 0.5rem 1.625rem 0.5rem 1.188rem;
$sidebar-icon-only-submenu-padding: 0 0 0 1.5rem;

$icon-only-collapse-width: 190px;
$icon-only-menu-bg-light: #e7e7e7;
///////// NAVBAR ////////
/// // scss-docs-start theme-color-variables
$blue: #12344b !default;
$action-transition-duration: 0.25s;
$action-transition-timing-function: ease;
$border-radius: 8px;
$primary: $blue !default;


$component-active-color: $white !default;
$component-active-bg: $primary !default;
$spacer: 1rem !default;

$border-width: 1px !default;
$variable-prefix: bs- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix: $variable-prefix !default;
$navbar-height: 97px;
$navbar-bg: #f4f5f7;
$navbar-icon-font-size: 0.9375rem;

$navbar-height-small: 70px;
$navbar-menu-color: #6c7383;
$navbar-font-size: $sidebar-menu-font-size;
$navbar-icon-font-size: 1.625rem;
$navbar-link-color: #212121;
$navbar-menu-icon-color: #212121;


// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s, `<ul>`s or `<ol>`s.
// NavBar
// Navs

// scss-docs-start nav-variables
$nav-link-padding-y: 0.5rem !default;
$nav-link-padding-x: 1rem !default;
$nav-link-font-size: null !default;
$nav-link-font-weight: null !default;
$nav-link-color: var(--#{$prefix}link-color) !default;
$nav-link-hover-color: var(--#{$prefix}link-hover-color) !default;
$nav-link-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out !default;
$nav-link-disabled-color: $gray-600 !default;

$nav-tabs-border-color: $gray-300 !default;
$nav-tabs-border-width: $border-width !default;
$nav-tabs-border-radius: $border-radius !default;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $gray-700 !default;
$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

$nav-pills-border-radius: $border-radius !default;
$nav-pills-link-active-color: $component-active-color !default;
$nav-pills-link-active-bg: $component-active-bg !default;
// scss-docs-end nav-variables

// Navbar

// scss-docs-start navbar-variables
$navbar-padding-y: $spacer * 0.5 !default;
$navbar-padding-x: null !default;

$navbar-nav-link-padding-x: 0.5rem !default;
.sidebar-icon-only .sidebar .nav .nav-item {
    position: relative;
}
.sidebar-icon-only .sidebar .nav:not(.sub-menu) .nav-item:hover .nav-link  {
    border-radius: 0;
}
.sidebar .nav.sub-menu .nav-item .nav-link i:hover {
    color: white;

}
.sidebar-icon-only .sidebar .nav {
    // overflow: visible;
    // margin-left: 0 !important;
    // margin-right: 0 !important;
}
.sidebar-icon-only .sidebar .nav .nav-item .nav-link {
    // display: block;
    // padding-left: 0.5rem;
    // padding-right: 0.5rem;
    // text-align: center;
    // position: static;
}

 .sidebar :hover {

  .nav .nav-item .collapse {
    display: none;
  }
}
.sidebar-icon-only .sidebar .nav.sub-menu {
    padding: 5px;
}



.sidebar:hover{
     .nav .nav-item .nav-link .menu-title,
   .nav .nav-item .nav-link .badge,
     .nav .nav-item .nav-link .menu-sub-title {
        display: none;
    }
}
.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
    display: block;
    background: #12344b;
    color: white;
    border-radius: 0 0 5px 0;
    position: absolute;
    left: 68px;
    width: 190px;
}
.sidebar-icon-only .sidebar {
    width: 60px;
}
.sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-arrow {
    display: none;
}
.sidebar-boxshadow {
    box-shadow: 0 4px 4px 0 #00000040;
}
.sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
   
    display: flex;
    align-items: center;
    background: white;
    color: #d62828;
    font-size: 14px;
    padding: .5rem 1.4rem;
    box-shadow: 4px 4px 5px hsla(0,0%,47%,.349);
    left: 54px;
    position: absolute;
    text-align: left;
    top: 0;
    bottom: 0;
  
    width: 190px;
    z-index: 1;
    line-height: 1.8;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
    border-radius: 0 5px 0px 0px;
    background: #fff;
}
.sidebar-icon-only .sidebar {
    overflow: visible;
}
.sidebar {
    height: calc(100vh - 69px);
    padding: 0px;
    box-shadow: 0 4px 4px 0 #00000040;
    position: fixed;
    margin-top: 100px;
    background:linear-gradient(174.2deg,#fafcff 2.48%,#ffc4c4 96.72%);
    overflow: auto;
    width: 200px;
    z-index: 999;
    transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;

    .nav {
        // overflow: hidden;
        flex-wrap: nowrap;
        flex-direction: column;
        margin-bottom: 60px;

        .nav-item {
            // @include transition-duration(0.25s);
            transition-duration: 0.25s;
            transition-property: background;
            margin-bottom: 5px;
            -webkit-transition-property: background;
            &:hover{
                .nav-link i{
                    color:#d62828 !important;
                    transition:0.4s
                }
            }
            .ReactCollapse--collapse {
                transition: height 500ms;
            }
            .collapse {
                z-index: 999;
            }

            .nav-link {
                @include display-flex;
                @include align-items(center);
          
                white-space: nowrap;
                // padding: $sidebar-menu-padding-top $sidebar-menu-padding-right $sidebar-menu-padding-bottom $sidebar-menu-padding-left;
                color: #9caac6;
                // @include transition-duration(0.45s);
                transition-duration: 0.45s;
                transition-property: color;
                -webkit-transition-property: color;
                cursor: pointer;

                i {
                    color: inherit;
                    font-size: 20px;
                    // margin-right: 8px;
                    &.menu-icon {
                        font-size: $sidebar-icon-font-size;
                        line-height: 1;
                        // margin-right: 1rem;
                        .rtl & {
                            margin-left: 0;
                            margin-right: auto;
                        }
                        color: #484848;
                        &:before {
                            vertical-align: middle;
                        }
                    }

                    &.menu-arrow {
                        display: inline-block;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        margin-left: auto;
                        margin-right: 0;
                        color: $sidebar-light-menu-arrow-color;
                        .rtl & {
                            margin-left: 0;
                            margin-right: auto;
                        }
                        &:before {
                            font-family: "themify";
                            font-style: normal;
                            display: block;
                            font-size: 0.687rem;
                            line-height: 10px;
                            @include transition(all 0.2s ease-in);
                        }
                    }
                }

                .menu-title {
                    color: inherit;
                    display: none;
                    line-height: 24px;
                    font-weight: 400;
                    font-size: 16px;

                    vertical-align: middle;
                }

                &.menu-expanded {
                    // background: $sidebar-light-menu-active-bg;
                    // border-radius: 0.437rem 0.437rem 0 0;
                    .menu-arrow {
                        transform: rotate(90deg);
                    }
                }
            }

            &.active {
                > .nav-link {
                    position: relative;
                    font-size: 16px;
                    font-weight: 700;
                    border-left: 2px solid white;
                    &:not(.menu-expanded) {
                        border-radius: 0px 20px 20px 0px;
                    }
                    i,
                    .menu-title,
                    .menu-arrow {
                        color: #d62828;
                        transition:0.4s;
                        font-weight: 400;
                    }
                    .menu-title {
                        font-size: 16px;
                        font-weight: 700;
                    }
                }
            }
        }

        &:not(.sub-menu) {
            margin-top: 1.45rem;
            margin-left: 0;
            margin-right: 0.375rem;
            > .nav-item {
                margin-top: 0.2rem;
                &:hover {
                    > .nav-link:not(.menu-expanded) {
                        color: white;
                        border-left: 2px solid white;
                    }
                }
            }
        }

        &.sub-menu {
            margin-bottom: 0;
            margin-top: 0;
            list-style: none;
            padding: $sidebar-submenu-padding;
            background: #12344b;

            .nav-item {
                // &::before {
                //     content: "";
                //     width: 5px;
                //     height: 5px;
                //     position: absolute;
                //     margin-top: 16px;
                //     border-radius: 50%;
                //     background: #b2b2b2;
                // }
                .nav-link {
                    color: #9CAAC6;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    text-align: left;
                    white-space: nowrap;
                    padding: $sidebar-submenu-item-padding;
                    position: relative;
                    font-size: $sidebar-submenu-font-size;
                    font-weight: $font-weight-medium;
                    line-height: 1;
             
                    &:hover {
                        color: white;
                        & .menu-icon{
                          
                                color: white;
                            
                        }
                    }
                    &.active {
                        color: white;
                        & .menu-icon{
                          
                            color: white;
                        
                    }
                    }
                }
            }
        }
    }
}

.sidebar-action{
    position: fixed;
    display:none;
    top:105px;
     z-index: 9;
     transition-duration: 0.25s;
}
/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
    .sidebar-offcanvas {
        margin-top: 50px;
        position: fixed;
        max-height: calc(100vh - #{$navbar-height});
        top: $navbar-height;
        bottom: 0;
        overflow: auto;
        right: -$sidebar-width-lg;
        -webkit-transition: all 0.25s ease-out;
        -o-transition: all 0.25s ease-out;
        transition: all 0.25s ease-out;
        &.active {
            right: 0;
        }
    }
}

.sidebar-icon-only .sidebar .nav .nav-item .nav-link {
    display: block;
    padding-left: .5rem;
    padding-right: .5rem;
    position: static;
    text-align: center;
}
.sidebar-icon-only .sidebar .nav .nav-item.active {
background: white;
}
.sidebar-icon-only .sidebar .nav .nav-item:hover {
    background: white;
    }