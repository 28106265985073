@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

.bg-esg-blue {
    background: #12344B;

}

.clr-esg-blue {
    color: #12344B;

}

.font-lato {
    font-family: 'Lato', sans-serif !important;
}

.text-big-one {


    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    leading-trim: both;

    text-edge: cap;
}

/* body, html {
    font-family: 'Lato', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: var(--gray-900, #101828);
} */
.p-tag-blue {
    background: #31597590;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.25rem 0.4rem;
    border-radius: 6px;
}

.clr-navy {

    color: var(--Navy, #315975);
}

.hover-navy {

    color: var(--Navy, #315975);
    background: white;
}

.hover-navy:hover {
    background: var(--Navy, #315975) !important;
    color: white !important;
}

.hover-red {

    color: #EE5724;
    background: white;
}

.hover-red:hover {
    background: #EE5724 !important;
    color: white !important;
}

.text-bold-500 {

    font-weight: 500;
}

.text-bold {

    font-weight: bold;
}

.fs-10 {

    font-size: 10px;
}

.fs-12 {

    font-size: 12px;
}

.fs-14 {

    font-size: 14px;
}

.fs-16 {

    font-size: 16px;
}

.fs-36 {

    font-size: 36px;
}

.fs-38 {

    font-size: 38px;
}

.fs-18 {

    font-size: 18px;
}

.fs-20 {

    font-size: 20px;
}
.fs-22 {

    font-size: 22px;
}
.fs-24 {

    font-size: 24px;
}
.fs-30 {

    font-size: 30px;
}

.lh-38 {
    line-height: 38px;
}

.lh-20 {
    line-height: 20px;
}

.lh-24 {
    line-height: 20px;
}

.text-micro {

    color: var(--Gray-2, #4F4F4F);
    leading-trim: both;
    text-edge: cap;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;

}

.hover-blue:hover {
    background: #31597550;
    cursor: pointer;
    color: white;

    font-weight: bold;
}



.cur-pointer {
    cursor: pointer !important;
}

.bg-smoke {
    background-color: #F9F9F9;
}

.bg-white {
    background-color: white;
}

.bg-g5 {
    background-color: #E0E0E0;
}

.fw-7 {
    font-weight: 700;
}

.fw-6 {
    font-weight: 600;
}

.fw-5 {
    font-weight: 500;
}

.fw-4 {
    font-weight: 400;
}

.clr-blk {
    color: #222
}

.clr-gray-900 {
    color: #101828
}
.status-tag-blue {
    background: rgba(189, 209, 247, 0.50);
    color: #5B8FF7;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 6px;
}
.status-tag-green {
    background: rgba(41, 199, 111, 0.10);
    color: #209F00;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 6px;
}

.status-tag-red {
    background: rgba(238, 87, 36, 0.10);
    color: #EE5724;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 6px;
}

.status-tag-orange {
    background: rgba(249, 223, 127, 0.20);
    color: #EB961D;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 6px;
}

.status-tag-gray {
    background: rgba(105, 110, 121, 0.10);
    color: #696E79;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 6px;
}

.p-datatable-wrapper {
    background: white;
}

.clr-gray-3 {
    color: #828282
}

.clr-navy-light {
    color: #315975;
    opacity: 0.1;
}

.bg-navy-light {
    background: #31597520;

}

.p-datepicker table td.p-datepicker-today>span.p-highlight {
    color: #315975;
    background: #EEF2FF;
}

.p-datepicker-title .p-datepicker-month {
    color: #315975;
    font-weight: bold;
}

.p-datepicker-title .p-datepicker-year {
    color: #315975
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    color: #315975;
    background: #31597510;
    font-weight: bold;
}

.big-number-navy {
    color: var(--Primary, #222);
    leading-trim: both;
    text-edge: cap;

    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    /* 100% */
}

.box-shadow-one {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.clr-delete {
    color: #EE5724;

}

.bg-delete {
    background: #EE5724;

}

.icon-layout {
    width: 44px;
    height: 37.923px;
    flex-shrink: 0;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.card {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important
}

.admin-card {
    padding: 20px;
    height: 180px;
    width: 100%;
    flex-direction: column;
    background: rgb(255, 255, 255);
    cursor: pointer;
    border-radius: 4px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important
}

.text-link {
    text-decoration: underline !important;
}

.text-none {
    text-decoration: none !important;
}


/* Media query for small screens (phones) */
@media only screen and (max-width: 767px) {

    /* Styles for small screens */
    .admin-card {
        height: 230px;
    }
}

/* Media query for medium screens (tablets) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .admin-card {
        height: 230px;
    }
}

/* Media query for large screens (desktops) */
@media only screen and (min-width: 1024px) {
    .admin-card {
        height: 230px;
    }
}
.clr-stt {
    color: #D62928;
}
.bg-stt {
    color: #D62928;
}

.swal2-title{
    font-family: 'Lato', sans-serif !important;
    font-size: 1.5em;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
    background-color:#315975 !important
}